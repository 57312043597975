//$pulse-palette: (
// 50: #E3F2FD,
// 100: #BBDEFB,
// 200: #90CAF9,
// 300: #64B5F6,
// 400: #42A5F5,
// 500: #2196F3,
// 600: #1E88E5,
// 700: #1976D2,
// 800: #1565C0,
// 900: #0D47A1,
// A100: #82B1FF,
// A200: #448AFF,
// A400: #2979FF,
// A700: #2962FF,
// contrast: (
//   50: rgba(black, 0.87),
//   100: rgba(black, 0.87),
//   200: rgba(black, 0.87),
//   300: white,
//   400: white,
//   500: white,
//   600: white,
//   700: white,
//   800: white,
//   900: white,
// )
//);


/* Pulse Theme - BEGIN */
$pulseThemeOld: (
  page-header-background: linear-gradient(to bottom, #c2d0dd, #cedce9, #a3b1bf),
  page-header-background-color: #cedce9,
  
  logo-color: #367DBE,
  
  column-header-background-color: #136494,
  column-header-foreground-color: #FFFFFF,
  column-header-filtered-background-color: #008001,
  column-header-filtered-foreground-color: #FFFFFF,
  
  greenStatus-background-color: #008001,
  greenStatus-foreground-color: #000000,
  
  orangeStatus-background-color: #ffa500,
  orangeStatus-foreground-color: #000000,

  redStatus-background-color: #ff0000,
  redStatus-foreground-color: #000000,
  
  yellowStatus-background-color: #ffff00,
  yellowStatus-foreground-color: #000000,

  button-border: 1px solid black,
  button-border-radius: 4px,
  button-color-default: #000000,
  button-background-color-default: #e9e4e4,
  button-background-color-hover: #F0F0F0,
  button-box-shadow-hover: 3px 3px 3px lightgray,
);

$pulseThemeNew: (
  page-header-background: linear-gradient(to bottom, #c2d0dd, #cedce9, #a3b1bf),
  page-header-background-color: #cedce9,
  
  logo-color: #367DBE,
  
  column-header-background-color: #42729E,
  column-header-foreground-color: #FFFFFF,

  column-header-filtered-background-color: #9CA777,
  column-header-filtered-foreground-color: #FFFFFF,
  
  greenStatus-background-color: #9CA777,
  greenStatus-foreground-color: #000000,
  
  orangeStatus-background-color: #FFBC47,
  orangeStatus-foreground-color: #000000,

  redStatus-background-color: #C63D2F,
  redStatus-foreground-color: #FFFFFF,
  
  yellowStatus-background-color: #FFFAA0,
  yellowStatus-foreground-color: #000000,

  button-border: 1px solid black,
  button-border-radius: 4px,
  button-color-default: #000000,
  button-background-color-default: #e9e4e4,
  button-background-color-hover: #F0F0F0,
  button-box-shadow-hover: 3px 3px 3px lightgray,
);

//:root {
  /* This generates all of the css variables that we will use later */
  [data-theme='old'] {
    @each $key, $var in $pulseThemeOld {
      --pulse-#{$key}: #{$var};
    }
  }

  [data-theme='light'] {
    @each $key, $var in $pulseThemeNew {
      --pulse-#{$key}: #{$var};
    }
  }
//}

/* Spacer to keep the right alight buttons in the header to the right */
.spacer {
    flex: 1 1 auto;
  }
/* Pulse Theme - END */