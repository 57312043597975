/* You can add global styles to this file, and also import other style files */

/* Angular Material Related Styles --- BEGIN */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Override the default font sizes
$custom-typography: mat.define-typography-config(
    $font-family: 'Arial, Helvetica',
    $body-1: mat.define-typography-level(12px, 20px, 400),
    $body-2: mat.define-typography-level(12px, 20px, 400),
    $subtitle-1: mat.define-typography-level(12px, 20px, 400),
    $subtitle-2: mat.define-typography-level(12px, 20px, 400),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import 'app/styling/PulseTheme.scss';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette(mat.$blue-palette);
$theme-accent: mat.define-palette(mat.$light-blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: $custom-typography, //mat.define-typography-config()
  density: -3,
));

//--mat-toolbar-title-text-line-height

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);



/* Override the track part of the toggle so it looks nicer compared to the default */
.mdc-switch {
    --mdc-switch-track-height: 24px;
    --mdc-switch-track-shape: 10px;
}
/* Angular Material Related Styles --- END */



/* Our styles --- BEGIN */
mat-card.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

.grid {
    width: 100%;
    height: 100%;
}


.SaveFilterButton {
    margin: 8px 8px 8px 0;
}

.ExcelExportButton {
    margin: 8px 8px 8px 0;
    color: #000000;
}

.ClearFiltersButton {
    margin: 8px 8px 8px 0;
    color: #000000;
}

button.DisabledButton {
    background-color: #F0F0F0 !important;
    border: 1px solid #969696 !important;
    color: #969696 !important;
}
    button.DisabledButton:hover {
        box-shadow: none !important;
    }



.SavedFilterDropdown {
    font-size: 14pt;
    font-weight: normal;
    margin-right: 8px;
}

.SavedFilterText {
    flex: 1 1 auto;
    min-width: 220px;
    margin-left: 5px;
}

.SavedFilterActionButton {
    flex: 0 0 30px;
}

mat-option .mdc-list-item__primary-text {
    display: block !important;
    width: 100%
}

.headerItem {
  font-size: 12px !important;
  margin-right: 8px;
}
  .headerItem:last-child {
      margin-left: auto;
  }

mat-card.footerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}
/* Our styles --- END */





/* AG-Grid Related Styles --- BEGIN */
@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-alpine.css';

.ag-theme-alpine {
    --ag-grid-size: 4px;                // Reduce the size of the row height
    --ag-list-item-height: 20px;        // Reduce the size of the row height
    --ag-header-column-resize-handle-color: white;
    --ag-header-column-separator-color: white;
    --ag-header-foreground-color: var(--pulse-column-header-foreground-color);
    --ag-header-background-color: var(--pulse-column-header-background-color);
    --ag-header-font-family: Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif";
}

/* This targets the column header's text */
.ag-header-cell-label {
    white-space: pre-wrap !important;
    text-align: center;
}

.ag-column-drop-empty-message,                          /* Targets the message in the 'Group By' area */
.ag-column-drop-cell-text,                              /* Targets the column name in the 'Group By' area */
span.ag-icon-grip,                                      /* Targets the dots in the 'group by' column's area */
span.ag-column-drop-icon,                               /* Targets the left most icon in the 'group by' header */
div[ref='eButtonWrapper'] > button > span.ag-icon-filter,   /* Targets the filter icon in the quick column filter */
span[ref='eButton'] > span.ag-icon-cancel,                  /* Targets the "remove column" button in the 'group by' header */
span[ref^='eSort'] > span,                              /* Targets the 'sorted state' icon */
span[ref='eMenu'] > span,                               /* Targets the hamburger menu icon on the ag-grid column header */
span[ref='eFilter'] > span                              /* Targets the filter icon on the ag-grid column header */
{
    color: var(--pulse-column-header-foreground-color) !important;
}

/* Fix the color of the filter text since the .ag-column-drop-cell-text element typically overrides it */
.ag-text-field-input {
    color: black !important;
}

.ag-header-cell {
    //background: var(--pulse-column-header-background) !important;
    background-color: var(--pulse-column-header-background-color) !important;
    color: var(--pulse-column-header-foreground-color) !important;
    font-size: 12px;
}


.ag-header-cell-filtered {
    background-color: var(--pulse-column-header-filtered-background-color) !important;
    color: var(--pulse-column-header-filtered-foreground-color) !important;
    font-size: 12px;
}



/** These need to match what is is /core/PulseTheme - BEGIN **/
// Red Background
.redCell {
    background-color: var(--pulse-redStatus-background-color) !important;
    color: var(--pulse-redStatus-foreground-color) !important;
}

.orangeCell {
    background-color: var(--pulse-orangeStatus-background-color) !important;
    color: var(--pulse-orangeStatus-foreground-color) !important;
}

.yellowCell {
    background-color: var(--pulse-yellowStatus-background-color) !important;
    color: var(--pulse-yellowStatus-foreground-color) !important;
}

.greenCell {
    background-color: var(--pulse-greenStatus-background-color) !important;
    color: var(--pulse-greenStatus-foreground-color) !important;
}
/** These need to match what is is /core/PulseTheme - END **/



/* AG-Grid Related Styles --- END */



/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { 
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

